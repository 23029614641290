/* eslint-disable no-nested-ternary */
import { Avatar, Button } from 'antd';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SignOut } from 'phosphor-react';
import { ROUTES } from '../../../common/constants';
import { useApp } from '../../../providers/AppContext';
import CaretRight from '../../imageComponent/CaretRight';

function AccountProfile({ shortName, data = [], profileImage, onClose }) {
  const router = useRouter();
  const {
    state: { currentUser }
  } = useApp();

  const handleSignOut = async () => {
    onClose();
    router.push(ROUTES.LOGOUT);
  };

  return (
    <div className="account-profile">
      <div className="account-profile-header">
        <div>
          <Avatar
            {...(profileImage && {
              src: profileImage
            })}
            className="bg-blue-2 label-l text-black-90"
            size="large"
          >
            {shortName}
          </Avatar>

          <div>
            {currentUser?.currentSubscriptionPlan?.planName && (
              <div className="plan-name">
                {currentUser?.currentSubscriptionPlan?.planName}
              </div>
            )}{' '}
            {currentUser?.currentSubscriptionPlan?.startedAt && (
              <div className="plan-date">
                {moment(currentUser?.currentSubscriptionPlan?.startedAt).format(
                  'MMMM YYYY'
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="profile-menu">
        <ul>
          {data?.map((item) => {
            return (
              <li
                key={item?.id}
                className={
                  router.pathname === `${item?.href ? item?.href : '#'}`
                    ? 'active'
                    : ''
                }
                onClick={onClose}
              >
                <Link prefetch={false} href={item?.href ? item?.href : '#'}>
                  {item.icon}
                  {item.title}
                  <CaretRight />
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="profile-btn">
          <Button
            className="cs-button between"
            size="large"
            type="primary"
            block
            onClick={handleSignOut}
          >
            Logout
            <SignOut size={20} />
          </Button>
        </div>
      </div>
    </div>
  );
}
export default AccountProfile;
