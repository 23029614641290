import {
  createInstance,
  MatomoProvider,
  useMatomo
} from '@datapunt/matomo-tracker-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ONE_TRUST_COOKIE_CONSENT_GROUPS } from '../common/constants';

const MATOMO_URL = process.env.NEXT_APP_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_APP_MATOMO_SITE_ID;

let instance = null; // Matomo instance should be null if no consent

function MatomoTrackPage() {
  const { trackPageView } = useMatomo();
  const router = useRouter();

  useEffect(() => {
    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.pathname]);

  return false;
}

export default function Matomo({ children }) {
  const [enabled, setEnabled] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (window?.OnetrustActiveGroups) {
      const groups = window.OnetrustActiveGroups.split(',');
      if (groups.includes(ONE_TRUST_COOKIE_CONSENT_GROUPS.PERFORMANCE)) {
        setEnabled(true);

        // Initialize Matomo only when cookies consent is granted
        if (!instance) {
          instance = createInstance({
            urlBase: MATOMO_URL,
            siteId: MATOMO_SITE_ID
          });
        }
      } else {
        setEnabled(false);
        instance = null; // Clear instance if consent is not given
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.pathname]);

  if (!enabled) {
    return children; // Just return children, do not track
  }

  return (
    <MatomoProvider value={instance}>
      <MatomoTrackPage />
      {children}
    </MatomoProvider>
  );
}
